<template>
  <div class="hero" :class="{'isplaying': isplaying } ">
    <div class="hero-video">
      <div class="hero-video-d">
          <video
            id="viD"
            ref="viD"
            preload="metadata"
            :controls="isplaying"
            class="for-desktop"
            :poster="imageCoverDesktop"
          >
            <source :src="videoDesktopURL" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
     </div>
     <div class="hero-video-m">
          <video
            id="viM"
            ref="viM"
            preload="metadata"
            :controls="isplaying"
            playsinline
            webkit-playsinline
            class="for-mobile"
            :poster="imageCoverMobile"
          >
            <source :src="videoMobileURL" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
      </div>
    </div>

    <a href="#" title="play" class="hero-play" @click.prevent="playVideo()">
      <svg width="86" height="86" viewBox="0 0 86 86" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_380:103)">
        <circle cx="43.2006" cy="43.4528" r="32.5165" fill="black"/>
        </g>
        <path d="M53.5543 44.3112L38.0201 53.2798L38.0201 35.3425L53.5543 44.3112Z" fill="#FEFCF9"/>
        <defs>
        <filter id="filter0_d_380:103" x="0.684082" y="0.936279" width="85.0329" height="85.033" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset/>
        <feGaussianBlur stdDeviation="5"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_380:103"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_380:103" result="shape"/>
        </filter>
        </defs>
      </svg>
    </a>


    <a href="#impact" class="more" >
      <span class="text">Our Impact</span>
      <span class="icon">
        <svg viewBox="0 0 28 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.33673 1.44922L14.0818 14.1943L26.8269 1.44922" stroke="white" stroke-width="2.51215"/>
        </svg>
      </span>
    </a>
  </div>
</template>

<script>
  import { reactive, toRefs, ref } from "vue";
  export default {
    name: 'HERO',
    setup(){
      const state = reactive({
        imageCoverMobile: require("@/assets/img/cover-intro-video-mobile.jpg"),
        imageCoverDesktop: require("@/assets/img/cover-intro-video.jpg"),
        videoDesktopURL: "https://prismic-io.s3.amazonaws.com/supporting/4deee9d1-4bf9-4a25-a49e-92858015f23e_video_desktop.mp4",
        videoMobileURL: "https://prismic-io.s3.amazonaws.com/supporting/8bff26d0-74a4-4fc2-8168-a6b43551846f_video_mb.mp4",
        videoElement: null,
        videoElementMobile: null,
        isplaying: false,
      });

      const viD = ref(null);
      const viM = ref(null);


      const checkMobile = () => {
        let w = window.innerWidt || document.documentElement.clientWidth || document.body.clientWidth;
        return w < 768 ? true : false;
      };

      const playVideo = () => {
        state.isplaying = true
        let isMobile = checkMobile();

        const viD = document.getElementById('viD');
        const viM = document.getElementById('viM');

        console.log(viM);

        if (isMobile) {
          viM.play();

        } else {
          viD.play();
        }
      };

      window.addEventListener('resize', () => {
        state.isplaying = false;

        const viD = document.getElementById('viD');
        const viM = document.getElementById('viM');

        viM.stop();
        viD.stop();
      });


      checkMobile();

      return {
        ...toRefs(state),
        playVideo,
        viD,
        viM
      }
    }
  }
</script>

<style lang="scss" scoped>
  .hero {
    box-sizing: border-box;
    // background: $red;
    height: 100vh;
    overflow: hidden;
    position: relative;
    margin-bottom: 50px;

    .more {
      background: #000;
      border-radius: 100%;
      bottom: 30px;
      box-shadow: 0 2.51215px 6px rgba(0, 0, 0, .3);
      box-sizing: border-box;
      color: #fff;
      // display: none;
      font-size: 14px;
      font-weight: 600;
      height: 130px;
      outline: 0 none;
      overflow: hidden;
      padding: 20px 20px;
      position: absolute;
      right: 5px;
      text-transform: uppercase;
      transform: scale(.8);
      transition: all 300ms ease;
      width: 130px;

      span {
        display: block;

        &.text {
          margin-top: 30px;
          transition: all 300ms ease;
        }
      }

      svg {
        display: block;
        width: 100%;
      }

      .icon {
        margin: 15px auto;
        transition: all 300ms ease;
        width: 28px;
      }

      &:hover,
      &:active,
      &:focus {
        color: $red;
        transform: scale(1.1);

        svg path {
          stroke: $red;
        }

        .icon {
          transform: translate(0,5px);
        }
      }
    }

    .hero-play {
      width: 86px;
      height: 86px;
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -43px;
      margin-top: -43px;
    }

    &.isplaying {
      .hero-play,
      .more {
        display: none;
      }
    }

    &-video {
      &::after {
        content: '';
        display: block;
        padding-top: 177.7%;
        width: 100%;
      }
    }

    &-video {

      video {
        width: 100%;
        height: auto;
      }

      &-d { display: none; }
      &-m { display: block; }
    }
  }

  @media screen and (min-width: 992px) {
    .hero {
      &-video {
        &-d { display: block; }
        &-m { display: none; }
      }
    }
  }

  @media screen and (min-width: 1366px) {
    .hero {
      min-height: 98vh;

      .more {
        bottom: 10px;
        right: 30px;
        transform: scale(1);
      }

      &-video {
        &::after {
          padding-top: 0;
        }
      }
    }
  }

</style>
